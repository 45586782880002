import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@components/Layout'
import Link from 'gatsby-link'
import { Seo } from '@components/Seo'
import PageTitle from '@components/PageTitle'
import { Article, TitleParagraph } from '@global'

const SchoolIndex = ({ data }) => (
  <Layout>
    <Seo title="Local Schools | The Town and Business Guide For Residents and Visitors" />
    <PageTitle title="Local Schools" />
    <TitleParagraph>Below is a list of local schools In Sowerby Bridge, for Nurseries please check our Business directory</TitleParagraph>

    <Article>
      {data.allDatoCmsCommunityLink.edges.map(({ node }) => (
        <div className="post">
          <h1 className="post-title">
            <Link to={`/community/${node.slug}`}>
              <h2>{node.title}</h2>
            </Link>
          </h1>
        </div>
      ))}
    </Article>
  </Layout>
)

export const Schoolgraph = graphql`
  {
    allDatoCmsCommunityLink(filter: { organisation: { title: { eq: "School" } } }) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`

export default SchoolIndex
